<template>
<b-card no-body>
  <template #header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="card-title">Workshops</h5>
      <b-button v-if="parent.moduleRole('add_workshop')" variant="info" class="btn-rounded" @click="openForm()">
        <i class="fas fa-plus-circle mr-2"></i>
        Add Workshop
      </b-button>
    </div>
  </template>
  
  <b-card-body>
    <b-row>
      <b-col md>
      </b-col>
      <b-col md="auto">
        <div class="d-flex">
            <b-form @submit.prevent="parent.doFilter(obj+'-data',parent.filter)" class="d-flex">
            <b-input-group>
            <b-form-input v-model="parent.filter.searchWorkshop" v-on:keyup.13="$emit('searchWorkshop')" placeholder="Type keyword then enter..."></b-form-input>
            <b-input-group-append>
              <b-button @click="parent.doFilter(obj+'-data',parent.filter)" variant="success"><i class="fas fa-search"></i></b-button>
            </b-input-group-append>
            </b-input-group>
            <b-button
              class="ml-1 d-inline-flex align-items-center"
              variant="outline-success"
              @click="parent.doReset(obj+'-data')"
            >Reset</b-button>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-card-body>

  <b-table
    :fields="fields"
    :items="dataList||[]"
    :per-page="perPage"
    :busy="!dataList||processPage"
    responsive
    show-empty
  > 
    <template #empty>
      <div class="text-center">
        <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
        <h4>No Workshop Listed Yet</h4>
      </div>
    </template>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(number)="v">
      {{(data.per_page*(data.current_page-1))+v.index+1}}
    </template>

    <template #cell(workshop_name)="data">
      <div class="d-flex align-items-center">
        <div class="font-weight-bold">{{ data.value }}</div>
        <b-badge class="ml-2" v-if="data.item.is_active == 'Y'" variant="success">Active</b-badge>
        <b-badge class="ml-2" v-else variant="danger">Inactive</b-badge>
      </div>
      <small>{{ data.address }}</small>
    </template>

    <template #cell(pic_name)="data">
      <span v-if="!(data.item.pic_name||[]).length">-</span>
      <div v-else class="my-1" v-for="cp in data.item.pic_name" :key="cp.name">{{cp.gender}} {{ cp.name }}</div>
    </template>
    
    <template #cell(pic_telpon)="data">
      <span v-if="!(data.item.pic_telpon||[]).length">-</span>
      <div v-else class="my-1" v-for="cp in data.item.pic_telpon" :key="cp.telephone">{{ cp.telephone }}</div>
    </template>

    <template #cell(pic_fax)="data">
      <span v-if="!(data.item.pic_fax||[]).length">-</span>
      <div v-else class="my-1" v-for="cp in data.item.pic_fax" :key="cp.fax">{{ cp.fax }}</div>
    </template>


    <template #cell(actions)="data">
      <b-button v-if="parent.moduleRole('update_workshop')" class="btn-icon" variant="outline-info" @click="openForm(data.item, data.index)">
        <i class="fas fa-pencil-alt"></i>
      </b-button>
      <b-button class="btn-icon" variant="outline-warning" @click="doChangeStatus(data.item)">
        <i class="fas fa-cog"></i>
      </b-button>
      <b-button v-if="parent.moduleRole('delete_workshop')" class="btn-icon" variant="outline-danger" @click="doDelete(data.item)">
        <i class="fas fa-trash-alt"></i>
      </b-button>
    </template>
  </b-table>

  <template #footer>
    <b-pagination
      v-if="(data.total||0) > perPage"
      class="mb-0 justify-content-end"
      v-model="currentPage"
      :per-page="data.per_page"
      :total-rows="data.total||0"
    />
  </template>

  <b-modal
    id="modalWorkshop"
    :title="input.index !== false ? 'Update ' + 'Workshop' : 'Add '+'Workshop'"        
    size="lg"
    @ok="doSubmitWorkshop" 
    :busy="parent.loadingOverlay"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
    ok-title="Save Changes"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormWorkshop"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitWorkshop(false))">
          <b-row>
            <b-col lg="6" md="6" sm="12">
              <b-form-group label-for="workshopArea" label="Area">
                <b-form-row>
                  <b-col>
                    <v-select
                      placeholder="Select Area"
                      v-model="input.region"
                      :options="mrRegion"
                      :reduce="v => v.id" label="region_id"
                    />
                    <VValidate 
                      name="Area" 
                      v-model="input.region" 
                      :rules="{required:1}" 
                    />
                  </b-col>
                  <b-col md="auto">
                    <b-button v-b-tooltip.hover="'Add New Area'" variant="outline-success" @click="openFormRegion"><i class="fas fa-plus"></i></b-button>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group label="Category">
                <v-select
                  placeholder="Select Category"
                  v-model="input.category"
                  :options="mrCategory"
                  :reduce="v => v.value"
                />
                <VValidate 
                  name="Category" 
                  v-model="input.category" 
                  :rules="{required:1}" 
                />
              </b-form-group>
              <b-form-group label-for="workshopName">
                <template #label>
                  Workshop Name <strong class="text-danger">*</strong></template>
                <b-form-input id="workshopName" v-model="input.workshop_name" />
                <VValidate 
                  name="Workshop Name" 
                  v-model="input.workshop_name" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                />
              </b-form-group>
              <b-form-group label-for="workshopAddress">
                <template #label>
                  Address <strong class="text-danger">*</strong></template>
                <b-form-textarea id="workshopAddress" v-model="input.workshop_address"/>
                <VValidate 
                  name="Workshop Name" 
                  v-model="input.workshop_address" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                />
              </b-form-group>
              <b-form-group label="Phone Number" label-for="workshopNumber">
                <b-form-row
                  v-for="(v,k) in input.pic_telpon"
                  :key="k"
                  class="mb-3"
                > <b-col md="9">
                    <b-input-group>
                      <template #prepend>
                        <span class="input-group-text"><i class="icon-phone"></i></span>
                      </template>
                      <b-form-input id="workshopNumber" v-model="v.telephone"/>
                    </b-input-group>
                    <VValidate 
                      :name="'Phone Number'+(k+1)" 
                      v-model="v.telephone" 
                      :rules="{regex:/^[0-9\+-]+$/ }"
                    />
                  </b-col>
                  <b-col md="3">
                    <b-button
                      v-if="k==input.pic_telpon.length-1"

                      class="btn-icon mr-1"
                      variant="outline-success"
                      v-b-tooltip.hover="'Add Phone'"

                      @click="input.pic_telpon.push({telephone:''})"
                    >
                      <i class="fas fa-plus"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-danger"
                      v-b-tooltip.hover="'Delete Phone'"

                      @click="input.pic_telpon.splice(k, 1)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </b-col>
                </b-form-row>
                <div v-if="!(input.pic_telpon||[]).length">
                  <b-button
                    variant="outline-success"
                    v-b-tooltip.hover="'Add Contact'"

                    @click="input.pic_telpon.push({telephone:''})"
                  >
                    Add New Contact <i class="fas fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
              <b-form-group label="Faximile" label-for="workshopfax">
                <b-form-row
                  v-for="(v,k) in input.pic_fax"
                  :key="k"
                  class="mb-3"
                > <b-col md="9">
                    <b-input-group>
                      <template #prepend>
                        <span class="input-group-text"><i class="icon-phone"></i></span>
                      </template>
                      <b-form-input id="workshopfax" v-model="v.fax"/>
                    </b-input-group>
                    <VValidate 
                      :name="'Faximile'+(k+1)" 
                      v-model="v.fax" 
                      :rules="{regex:/^[0-9\+-]+$/ }"
                    />
                  </b-col>
                  <b-col md="3">
                    <b-button
                      v-if="k==input.pic_fax.length-1"

                      class="btn-icon mr-1"
                      variant="outline-success"
                      v-b-tooltip.hover="'Add Faximile'"

                      @click="input.pic_fax.push({fax:''})"
                    >
                      <i class="fas fa-plus"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-danger"
                      v-b-tooltip.hover="'Delete Faximile'"

                      @click="input.pic_fax.splice(k, 1)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </b-col>
                </b-form-row>
                <div v-if="!(input.pic_fax||[]).length">
                  <b-button
                    variant="outline-success"
                    v-b-tooltip.hover="'Add Contact'"

                    @click="input.pic_fax.push({fax:''})"
                  >
                    Add New Faximile <i class="fas fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
              <b-form-group label="Contact Person" label-for="workshopContactPerson">
                <b-form-row v-for="(v,k) in input.pic_name"
                  :key="k" class="mb-3">
                  <b-col md="3">
                    <v-select :options="prefixType" v-model="v.gender" />
                  </b-col>
                  <b-col md="6">
                    <b-input-group>
                      <template #prepend>
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </template>
                      <b-form-input id="workshopContactPerson" v-model="v.name"></b-form-input>
                    </b-input-group>
                    
                    <VValidate 
                      :name="'Name'+(k+1)" 
                      v-model="v.name" 
                      :rules="v.gender ? 'required' : ''"
                    />
                  </b-col>
                  <b-col md="3">
                    <b-button
                      v-if="k==input.pic_name.length-1"

                      class="btn-icon mr-1"
                      variant="outline-success"
                      v-b-tooltip.hover="'Add Person'"

                      @click="input.pic_name.push({name:'', gender:''})"
                    >
                      <i class="fas fa-plus"></i>
                    </b-button>
                    <b-button
                      class="btn-icon"
                      variant="outline-danger"
                      v-b-tooltip.hover="'Delete Person'"

                      @click="input.pic_name.splice(k, 1)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </b-col>
                </b-form-row>
                
                <div v-if="!(input.pic_name||[]).length">
                  <b-button
                    variant="outline-success"
                    v-b-tooltip.hover="'Add Person'"

                    @click="input.pic_name.push({name:'', gender:''})"
                  >
                    Add New Person <i class="fas fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12">
              <b-form-group label-for="mouNo">
                <template #label>
                  Mou No
                  </template>
                <b-form-input id="mouNo" v-model="input.mou_no"/>
              </b-form-group>   
              <b-form-group label-for="mouDate">
                <template #label>
                  Mou Date
                </template>
                <div class="input-group mb-3">
                  <datepicker input-class="form-control transparent" v-model="input.mou_date" placeholder="Select Publish Date">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                  </div>
                </div>
              </b-form-group>   
              
              <b-form-group label-for="mouExpDate">
                <template #label>
                  Mou Exp. Date
                </template>
                <div class="input-group mb-3">
                  <datepicker input-class="form-control transparent" v-model="input.mou_expired_date" placeholder="Select Publish Date">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i class="ti-calendar"></i></span>
                  </div>
                </div>
              </b-form-group>   
              
              <b-form-group label-for="compName">
                <template #label>
                  Company Name
                </template>
                <div class="input-group mb-3">
                  <b-form-input id="CompName" v-model="input.company_name"/>                
                </div>
              </b-form-group>
              
              <b-form-group label-for="siup">
                <template #label>
                  SIUP
                </template>
                <div class="input-group mb-3">
                  <b-form-input id="siup" v-model="input.siup"/>                
                </div>
              </b-form-group>

              <b-form-group label-for="TDP">
                <template #label>
                  TDP
                </template>
                <div class="input-group mb-3">
                  <b-form-input id="TDP" v-model="input.tdp"/>                
                </div>
              </b-form-group>

              <b-form-group label-for="NPWP">
                <template #label>
                  NPWP
                </template>
                <b-form-input id="NPWP" v-model="input.npwp"/>                
                <VValidate 
                  name="NPWP" 
                  v-model="input.npwp" 
                  :rules="{regex:/^[0-9. -]+$/}"
                />
              </b-form-group>
              
              <b-form-group label-for="akta">
                <template #label>
                  Akta
                </template>
                <div class="input-group mb-3">
                  <b-form-input id="akta" v-model="input.akta"/>                
                </div>
              </b-form-group>
              
              <b-form-group label-for="norek">
                <template #label>
                  No Rekening Tabungan
                  <!--<strong class="text-danger">*</strong>-->
                </template>
                <b-form-input id="norek" v-model="input.no_rek_tabungan"/>                
                <VValidate 
                  name="No. Rekening" 
                  v-model="input.no_rek_tabungan" 
                  :rules="{regex:/^[0-9]+$/}"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>

  <b-modal
    id="modalRegion"
    :title="input.index !== false ? 'Update ' + 'Region' : 'Add '+'Region'"        
    @ok="doSubmitRegion" 
    :busy="parent.loadingOverlay"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormRegion"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitRegion(false))">
          <b-form-group label-for="regionID">
            <template #label>
              Region Name (ID) <strong class="text-danger">*</strong></template>
            <b-form-input id="regionID" v-model="inputReg.region_id" />
            <VValidate 
              name="Region Name (ID)" 
              v-model="inputReg.region_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
            />
          </b-form-group>
          <b-form-group label-for="regionEN">
            <template #label>
              Region Name (EN) <strong class="text-danger">*</strong></template>
            <b-form-input id="regionEN" v-model="inputReg.region_en" />
            <VValidate 
              name="Region Name (EN)" 
              v-model="inputReg.region_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
            />
          </b-form-group>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'
const _ = global._
import Datepicker from 'vuejs-datepicker'

export default{
  components:{ Datepicker },  
  props:{ 
    data:[Array, Object, Boolean], 
    mrRegion:Array 
  },
  data(){
    return {
      perPage: 20,
      currentPage: 1,
      processPage:false,
      fields:[
        {
          key:'number', label:'#'
        },
        {
          key:'region_id', label:'Area'
        },
        {
          key:'workshop_name', label:'Workshop Name'
        },
        {
          key:'pic_name', label:'Contact Person'
        },
        {
          key:'pic_telpon', label:'Contacts Telephone'
        },
        {
          key:'pic_fax', label:'Fax'
        },
        'actions'
      ],
      input:{},
      obj: 'workshop',
      inputReg:{},
      mrCategory:[
        { value:'PARTNER', label:'Partner' },
        { value:'AUTHORIZED', label:'Authorized' },
      ],
      prefixType: ['Mr.', 'Ms.']
    }
  },
  computed:{
    dataList(){return this.data.data },
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  methods:{
    getCPNumber(item){
      const dt = JSON.parse(JSON.stringify(item))
     
      let dtPhone =  _.map(_.filter(dt, v=>{
        return !!v.phone
      }), v=>{
        return {
          type:"phone",
          number:v.phone
        }
      })
      dtPhone = _.uniqBy(dtPhone, 'number')

      let dtFax =  _.map(_.filter(dt, v=>{
        return !!v.fax
      }), v=>{
        return {
          type:"fax",
          number:v.fax
        }
      })
      dtFax = _.uniqBy(dtFax, 'number')

      return _.union(dtPhone, dtFax)
    },
    getCPName(item){
      const dt = JSON.parse(JSON.stringify(item))
      return _.uniqBy(dt, 'name')
    },
    openForm(row={}, index=false){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.index = index 
      this.$set(this.input, 'pic_contacts', this.input.pic_contacts||[])
      this.$set(this.input, 'pic_name', this.input.pic_name||[])
      this.$set(this.input, 'pic_telpon', this.input.pic_telpon||[])
      this.$set(this.input, 'pic_fax', this.input.pic_fax||[])
      this.$bvModal.show('modalWorkshop')
    },
    openFormRegion(){
      this.inputReg = {}
      this.$bvModal.show('modalRegion')
    },
    doSubmitWorkshop(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormWorkshop'].validate().then(success => {
        if (!success) return

        const input = this.input
        this.parent.loadingOverlay = true
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:input.index===false?'add-workshop':'update-workshop', 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)
            
            if(input.index===false){
              this.parent.submitCRUDToAlgolia('workshop_id_',resp.data.rowAlgoliaID, true)
              this.parent.submitCRUDToAlgolia('workshop_en_',resp.data.rowAlgoliaEN, true)
            }else{
              this.parent.submitCRUDToAlgolia('workshop_id_',resp.data.rowAlgoliaID, false, input.index)
              this.parent.submitCRUDToAlgolia('workshop_en_',resp.data.rowAlgoliaEN, false, input.index)
            }

            this.parent.restAPI('workshop-data')
            this.$bvModal.hide('modalWorkshop')

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.title = err.response?.data?.title
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doSubmitRegion(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormRegion'].validate().then(success => {
        if (!success) return

        const input = this.inputReg
        this.parent.loadingOverlay = true
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:'manage-region'
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200

            this.mrRegion.push(resp.data.row)
            this.$emit('update:mrRegion', this.mrRegion)
            this.$bvModal.hide('modalRegion')

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.title = err.response?.data?.title
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doChangeStatus(v){
      let changeStatus = v.is_active == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'update-status-workshop', 
              id:v.id,
              status: changeStatus
            })}, 
            "POST"
          )
            .then(res=>{
              this.parent.loadingOverlay = false 
              v.is_active = changeStatus

              let resp = res.data                                          
              this.parent.submitCRUDToAlgolia('workshop_id_',resp.data.rowAlgoliaID, false, v.id)
              this.parent.submitCRUDToAlgolia('workshop_en_',resp.data.rowAlgoliaEN, false, v.id)

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
        }
      })
    },
    doDelete(v){
      this.$swal({
        title: 'Delete this Workshop ?',        
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay = true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'delete-workshop', 
              id:v.id
            })}, 
            "POST"
          )
            .then(()=>{
              this.parent.loadingOverlay = false 
              this.parent.restAPI('workshop-data')
              this.parent.deleteFromAlgoliaList('workshop_en_',v.id)
              this.parent.deleteFromAlgoliaList('workshop_id_',v.id)
              this.$swal({
                title: 'Data Deleted Successfully!',
                icon: 'success',
              })
            })
        }
      })
    },
  },
  watch:{
    currentPage(v){
      this.processPage=true
      this.parent.restAPI('workshop-data', {page:v}, ()=>{
        this.processPage=false
      })
    }
  }
}
</script>