<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection :setting.sync="heroSetting" />
          <MainInfo :setting.sync="mainInfo" v-on:updateSeoTitle="updateSeoTitle(...arguments)"/>
          <InsuranceSection :data.sync="claimData"/>          
          <FAQSection :data.sync="faqData" />
          <WorkShopSection :data.sync="workshopData" :mrRegion.sync="mrRegion" />
        </b-tab>
        <b-tab title="SEO Settings">
          <SeoSection :setting.sync="seoPageSetting" ref="seoSectionClaim" />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'

import HeroSection from './HeroSection.vue'
import SeoSection from './SeoSection.vue'
import WorkShopSection from './WorkShopSection.vue'
import FAQSection from './FAQSection.vue'
import InsuranceSection from './InsuranceSection.vue'
import MainInfo from './MainInfo.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    HeroSection,
    SeoSection,
    WorkShopSection,
    FAQSection,
    InsuranceSection,
    MainInfo
  },
  data() {
    return {
      heroSetting:{},
      seoPageSetting:{},
      mainInfo:{},
      faqData:{data:false},
      claimData:{data:false},
      workshopData:{data:false},
      mrRegion:[]
    }
  },
  mounted(){
    this.getAllData()
  },
  computed: {
  },
  methods: {
    updateSeoTitle(v) {
      this.$refs.seoSectionClaim.updateTitle(v)
    },
    doFilter(slug, params={}){
      this.restAPI(slug, params)
    },
    doReset(slug){
      this.restAPI(slug)
      this.filter={}    
    },
    getAllData(){
      this.loadingOverlay=true
      this.restAPI('page-setting')
      this.restAPI('faq-data')
      this.restAPI('workshop-data')
      this.restAPI('insurance-data')
    },
    restAPI(slug, params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage+"/"+slug,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  watch:{
    $route(){
      this.getAllData()
    }
  }
}
</script>
