<template>
<b-card no-body>
  <template #header>
    <h5 class="card-title">Insurance Claim Information</h5>
  </template>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VFormInfo"
  >
    <b-form @submit.prevent="handleSubmit(doSubmitInfo())">
      <b-card-body>
        <b-alert v-if="setting.needSubmit && parent.moduleRole('update_main_info')" variant="warning" show>
          <b>{{ parent.Config.wording.detectUpdate }}</b>
        </b-alert>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="infoClaimTitleID">
              <template #label>Title (ID)<strong class="text-danger">*</strong></template>
              <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimTitleID" v-model="setting.title_id" @input="setting.needSubmit=true" />
              <VValidate 
                name="Title ID" 
                v-model="setting.title_id" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"         
              />
            </b-form-group>
            <b-form-group label-for="infoClaimDescID">
              <template #label>Description (ID)<strong class="text-danger">*</strong></template>
              <b-form-textarea id="infoClaimDescID" rows="4" v-model="setting.desc_id" @input="setting.needSubmit=true" />
              <VValidate 
                name="Description ID" 
                v-model="setting.desc_id" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"         
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="infoClaimTitleEN">
              <template #label>Title (EN)<strong class="text-danger">*</strong></template>
              <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimTitleEN" v-model="setting.title_en" @input="setting.needSubmit=true" />
              <VValidate 
                name="Title EN" 
                v-model="setting.title_en" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"         
              />
            </b-form-group>
            <b-form-group label-for="infoClaimDescEN">
              <template #label>Description (EN)<strong class="text-danger">*</strong></template>
              <b-form-textarea id="infoClaimDescEN" rows="4" v-model="setting.desc_en" @input="setting.needSubmit=true" />
              <VValidate 
                name="Description EN" 
                v-model="setting.desc_en" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"         
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-card no-body class="border">
          <b-tabs card>
            <b-tab 
              v-for="(key,no) in Object.keys(jsonData)"
              :key="key"
              :title="jsonData[key].heading" 
              :active="!no"
            >
              <b-row class="mt-2 mb-1">
                <b-col md=6>
                  <b-form-group>
                    <template #label>Heading (ID)<strong class="text-danger">*</strong></template>
                    <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="jsonData[key].title_id" @input="setting.needSubmit=true" />
                    <VValidate 
                      :name="'Heading ID-'+no" 
                      v-model="jsonData[key].title_id" 
                      :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"               
                    />
                  </b-form-group>
                </b-col>
                <b-col md=6>
                  <b-form-group>
                    <template #label>Heading (EN)<strong class="text-danger">*</strong></template>
                    <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" v-model="jsonData[key].title_en" @input="setting.needSubmit=true" />
                    <VValidate 
                      :name="'Heading EN-'+no" 
                      v-model="jsonData[key].title_en" 
                      :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"               
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table-lite
                :id="key"
                :fields="fieldProc"
                :items="jsonData[key].data||[]"
                :per-page="perPage[key]"
                :current-page="currentPage[key]"
                small
                class="mb-0"
              >
                <template #cell(img)="data">
                  <div class="procedure-icon p-1 bg-white d-inline-block">
                    <img :src="parent.uploader(data.value)"/>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <template v-if="parent.moduleRole('update_main_info')">
                    <b-button class="btn-icon" variant="outline-warning" @click="openAddProc(key, data.item, data.index)">
                      <i class="fas fa-pencil-alt"></i>
                    </b-button>
                    <b-button class="btn-icon" variant="outline-danger" @click="()=>{jsonData[key].data.splice(data.index,1); setting.needSubmit=true;}">
                      <i class="fas fa-trash-alt"></i>
                    </b-button>
                  </template>
                  <span v-else>-</span>
                </template>
                <template #custom-foot v-if="parent.moduleRole('update_main_info')">
                  <b-td colspan="99">
                    <div class="text-center">
                      <b-button variant="outline-info" class="btn-rounded" @click="openAddProc(key)">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Add Procedure
                      </b-button>
                    </div>
                  </b-td>
                </template>
              </b-table-lite>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-card-body>
      <b-card-footer v-if="parent.moduleRole('update_main_info')">
        <div class="text-right">
          <b-button type="submit" class="btn-rounded" variant="info">Save Changes</b-button>
        </div>
      </b-card-footer>
    </b-form>
  </validation-observer>
  <b-modal
    id="modalClaimProcedure"
    :title="input.index !== false ? 'Update ' + 'Procedure' : 'Add '+'Procedure'"    
    @ok="doSubmitProc"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
    ok-title="Save Changes"
  >
    <template v-if="Object.keys(input).length">
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormProc"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitProc(false))">
          <b-form-group label-for="procedureType">
            <template #label>Type<strong class="text-danger">*</strong></template>
            <b-form-radio-group
              id="procedureType"
              v-model="input.slug"
              :options="procedureTypeOptions"
              text-field="label"
              disabled
            />
          </b-form-group>
          <b-form-group>
            <template #label>Icon<strong class="text-danger">*</strong></template>
            <Uploader v-model="input.img" type="service-prosedur-icon"/>
            <VValidate 
              name="Icon" 
              v-model="input.img" 
              :rules="{required:1}"       
            />
          </b-form-group>
          <b-form-group label-for="procedureTitleID">
            <template #label>Title (ID)<strong class="text-danger">*</strong></template>
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="procedureTitleID" v-model="input.title_id" />
            <VValidate 
              name="Title (ID)" 
              v-model="input.title_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"       
            />
          </b-form-group>
          <b-form-group label-for="procedureTitleAltID">
            <template #label>Alt Image (ID)<strong class="text-danger">*</strong></template>            
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="procedureTitleAltID" v-model="input.alt_image_id" />
            <VValidate 
              name="Alt Image (ID)" 
              v-model="input.alt_image_id" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"       
            />
          </b-form-group>
          <b-form-group label-for="procedureTitleEN">
            <template #label>Title (EN)<strong class="text-danger">*</strong></template>            
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="procedureTitleEN" v-model="input.title_en" />
            <VValidate 
              name="Title (EN)" 
              v-model="input.title_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"       
            />
          </b-form-group>
          <b-form-group label-for="procedureTitleAltEN">
            <template #label>Alt Image (EN)<strong class="text-danger">*</strong></template>                        
            <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="procedureTitleAltEN" v-model="input.alt_image_en" />
            <VValidate 
              name="Alt Image (EN)" 
              v-model="input.alt_image_en" 
              :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"       
            />
          </b-form-group>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'

export default{
  props:{ setting:Object },
  data(){
    return {
      perPage: {
        proc_rugi_sebagian:3,
        proc_rugi_total:3,
      },
      currentPage: {
        proc_rugi_sebagian:1,
        proc_rugi_total:1,
      },
      fieldProc:[
        {
          key:'img',
          label:'Icon'
        },
        {
          key:'title_id',
          label:'Title (ID)'
        },
        {
          key:'title_en',
          label:'Title (EN)'
        },
        'actions'
      ],
      input:{},
      procedureTypeOptions: [
        { label: 'Partial Loss', value: 'proc_rugi_sebagian' },
        { label: 'Total Loss', value: 'proc_rugi_total' },
      ],
      timer: null,
    }
  },
  computed:{
    jsonData(){
      return (this.setting.json_data||{})
    },
    procSebagian(){
      return this.jsonData.proc_rugi_sebagian||{}
    },
    procTotal(){
      return this.jsonData.proc_rugi_total||{}
    },
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  methods:{
    openAddProc(slug, row={}, index=false){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.index = index 
      this.input.slug = slug 
      this.$bvModal.show('modalClaimProcedure')
    },
    doSubmitProc(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormProc'].validate().then(success => {
        if (!success) return
        const input = this.input
        let procData = this.setting.json_data[input.slug]

        if(input.index===false){
          // add
          let indexId = procData.data.findIndex(v => v.title_id.replace(' ','') == input.title_id)      
          if(indexId !== -1){
            let err = {}
            err.statusType = 400
            err.status = "error"
            err.message = " "
            err.title = "Title ID Exist"
            this.parent.loadingOverlay = false        
            return this.parent.doSetAlertForm(err)
          }
          let indexEn = procData.data.findIndex(v => v.title_en.replace(' ','') == input.title_en)      
          if(indexEn !== -1){
            let err = {}
            err.statusType = 400
            err.status = "error"
            err.message = " "
            err.title = "Title EN Exist"
            this.parent.loadingOverlay = false                    
            return this.parent.doSetAlertForm(err)
          }
          procData.data.push(input)
          this.setting.needSubmit=true
        }else{
          // edit
          let dataEdit = JSON.parse(JSON.stringify(procData.data))
          let index = dataEdit.findIndex(v => v.index == input.index)  
          let listNoIndex = dataEdit
          listNoIndex.splice(index,1)    
          let indexId = listNoIndex.findIndex(v => v.title_id.replace(' ','') == input.title_id)      
          if(indexId !== -1){
            let err = {}
            err.statusType = 400
            err.status = "error"
            err.message = " "
            err.title = "Title ID Exist"
            this.parent.loadingOverlay = false        
            return this.parent.doSetAlertForm(err)
          }
          let indexEn = listNoIndex.findIndex(v => v.title_en.replace(' ','') == input.title_en)      
          if(indexEn !== -1){
            let err = {}
            err.statusType = 400
            err.status = "error"
            err.message = " "
            err.title = "Title EN Exist"
            this.parent.loadingOverlay = false                    
            return this.parent.doSetAlertForm(err)
          } 

          this.$set(procData.data, input.index, input)
          this.setting.needSubmit=true
        }

        this.$bvModal.hide('modalClaimProcedure')
      })
    },
    doSubmitInfo(){
      this.$refs["VFormInfo"].validate().then(success => {
        if (!success) return
        let input = this.setting
        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {type:'update-main-info', id:input.id})}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            
            if(Object.keys(resp.data.rowAlgoliaID).length){
              this.parent.submitStaicContentAlgolia('claim-information',resp.data.rowAlgoliaID,'id')
            }
            if(Object.keys(resp.data.rowAlgoliaEN).length){
              this.parent.submitStaicContentAlgolia('claim-information',resp.data.rowAlgoliaEN,'en')
            }
            
            this.$emit('update:setting', input)

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    }
  },
  watch: {
    'input.title_id'(v){
        this.input.alt_image_id = v
    },
    'input.title_en'(v){
        this.input.alt_image_en = v
    },
    setting: {
      deep: true,
      handler(newVal){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('updateSeoTitle', newVal)
        }, 1000)
      }
    }
  }
}
</script>