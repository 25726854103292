<template>
<b-card no-body>
  <template #header>
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="card-title">Frequently Asked Questions</h5>
      <b-button v-if="parent.moduleRole('add_faq')" variant="info" class="btn-rounded" @click="openForm()">
        <i class="fas fa-plus-circle mr-2"></i>
        Add FAQ
      </b-button>
    </div>
  </template>
  
  <b-table
    :fields="fields"
    :items="dataList||[]"
    :per-page="perPage"
    :busy="!dataList||processPage"
    responsive
    show-empty
  > 
    <template #empty>
      <div class="text-center">
        <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
        <h4 align="center">No FAQ Listed yet</h4>
      </div>
    </template>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
    <template #cell(number)="v">
      {{(data.per_page*(data.current_page-1))+v.index+1}}
    </template>

    <template #cell(actions)="data">
      <b-button v-if="parent.moduleRole('update_faq')" class="btn-icon" variant="outline-warning" @click="openForm(data.item, data.index)">
        <i class="fas fa-pencil-alt"></i>
      </b-button>
      <b-button v-if="parent.moduleRole('delete_faq')" class="btn-icon" variant="outline-danger" @click="doDelete(data.item, data.index)">
        <i class="fas fa-trash-alt"></i>
      </b-button>
    </template>
  </b-table>

  <template #footer>
    <b-pagination
      v-if="(data.total||0) > perPage"

      class="mb-0 justify-content-end"
      v-model="currentPage"
      :per-page="data.per_page"
      :total-rows="data.total||0"
    />
  </template>

  <b-modal
    id="modalFaq"
    size="lg"
    :title="input.index !== false ? 'Update ' + 'FAQ' : 'Add '+'FAQ'"    
    @ok="doSubmitFAQ"
    :busy="parent.loadingOverlay"
    cancel-variant="secondary btn-rounded"
    ok-variant="primary btn-rounded"
    ok-title="Save Changes"
  >
    <b-overlay
      :show="parent.loadingOverlay"
      rounded="sm"
    >
      <validation-observer
        v-slot="{ handleSubmit }" ref="VFormFAQ"
      >
        <b-form @submit.prevent="handleSubmit(doSubmitFAQ(false))">
          <b-row>
            <b-col md="6">
              <b-form-group label-for="faqTitle">
                <template #label>Title (ID) <strong class="text-danger">*</strong></template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="faqTitle" v-model="input.title_id" />
                <VValidate 
                  name="Title ID" 
                  v-model="input.title_id" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"                    
                />
              </b-form-group> 
              <b-form-group label-for="faqContent">
                <template #label>Content (ID) <strong class="text-danger">*</strong></template>
                <CKEditor 
                  :value.sync="input.desc_id"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content ID" 
                  v-model="input.desc_id" 
                  :rules="{required:1}"                    
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="faqTitle">
                <template #label>Title (EN) <strong class="text-danger">*</strong></template>
                <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="faqTitle" v-model="input.title_en" />
                <VValidate 
                  name="Title EN" 
                  v-model="input.title_en" 
                  :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"                    
                />
              </b-form-group> 
              <b-form-group label-for="faqContent">
                <template #label>Content (EN) <strong class="text-danger">*</strong></template>
                <CKEditor 
                  :value.sync="input.desc_en"
                  :customToolbar="parent.Config.toolsBasicCK"
                />
                <VValidate 
                  name="Content EN" 
                  v-model="input.desc_en" 
                  :rules="{required:1}"                    
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--for submit -->
          <b-button type="submit" class="d-none"></b-button>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</b-card>
</template>
<script>
import Gen from '@/libs/Gen'
import CKEditor from '@/components/CKEditor'

export default{
  components:{ CKEditor },
  props:{ data:[Array, Object, Boolean] },
  data(){
    return {
      perPage: 5,
      currentPage: 1,
      processPage:false,
      fields:[
        {
          key:'number', label:'#'
        },
        {
          key:'title_id', label:'Title (ID)'
        },
        {
          key:'title_en', label:'Title (EN)'
        },
        'actions'
      ],
      input:{},
    }
  },
  computed:{
    dataList(){return this.data.data },
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  methods:{
    openForm(row={}, index=false){
      this.input = JSON.parse(JSON.stringify(row))
      this.input.index = index 
      this.$bvModal.show('modalFaq')
    },
    doSubmitFAQ(bvModalEvt){
      if(bvModalEvt){
        bvModalEvt.preventDefault()
      }
      this.$refs['VFormFAQ'].validate().then(success => {
        if (!success) return

        const input = this.input
        this.parent.loadingOverlay = true
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {
            type:input.index===false?'add-faq':'update-faq', 
            id:input.id
          })}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            let faqData = this.dataList
            if(input.index===false){
              faqData.push(resp.data.row)
              this.data.total = this.data.total+1
              this.parent.submitCRUDToAlgolia('faq_id_',resp.data.rowAlgoliaID, true)
              this.parent.submitCRUDToAlgolia('faq_en_',resp.data.rowAlgoliaEN, true)
            }else{
              this.$set(faqData, input.index, resp.data.row)
              this.parent.submitCRUDToAlgolia('faq_id_',resp.data.rowAlgoliaID, false, input.index)
              this.parent.submitCRUDToAlgolia('faq_en_',resp.data.rowAlgoliaEN, false, input.index)
            }
            this.$bvModal.hide('modalFaq')

        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.title = err.response?.data?.title
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    },
    doDelete(v){
      this.$swal({
        title: 'Delete this FAQ ?',        
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.parent.loadingOverlay = true

          Gen.apiRest(
            "/do/"+this.parent.modulePage, 
            {data: Object.assign({}, {
              type:'delete-faq', 
              id:v.id
            })}, 
            "POST"
          )
            .then(()=>{
              this.parent.loadingOverlay = false 
              this.parent.restAPI('faq-data')
              this.parent.deleteFromAlgoliaList('faq_en_',v.id)
              this.parent.deleteFromAlgoliaList('faq_id_',v.id)
              this.$swal({
                title: 'Data Deleted Successfully!',
                icon: 'success',
              })
            })
        }
      })
    },
  },
  watch:{
    currentPage(v){
      this.processPage=true
      this.parent.restAPI('faq-data', {page:v}, ()=>{
        this.processPage=false
      })
    }
  }
}
</script>